import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const HomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="w-full m-0 overflow-hidden">
      <Slider {...settings}>
        <div className="relative">
          {/* <video className="" playsInline autoPlay muted loop>
            <source className="" src="./img/slider1.mp4" type="video/mp4" />
          </video> */}
          <img src="./img/LIM.jpg" alt="" srcset="" />
          {/* <div
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
          >
            <div className="flex h-full items-center justify-center">
              <div className="px-md-0 px-14 text-center text-white">
                <h2 className="mb-4 text-md md:text-2xl font-semibold">
                  Automating and Developing Robotics Executing
                  Ideas,Transforming Business Practices.
                </h2>
                <div className="md:space-x-2">
                  <Link
                    type="button"
                    className="inline-block rounded border-2 border-white px-6 py-2 text-xs font-medium uppercase leading-tight text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
                    to="/product/1"
                    role="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="relative">
          <video className="" playsInline autoPlay muted loop>
            <source className="" src="./img/slider2.mp4" type="video/mp4" />
          </video>
          <div
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
          >
            <div className="flex h-full items-center justify-center">
              <div className="px-md-0 px-14 text-center text-white">
                <h2
                  className="mb-4 text-sm md:text-[22px] font-semibold p-3 rounded-[19px] md:rounded-[29px]	text-[#0848C9]"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                >
                  When Idea & Technology Work Together, It Creates a Masterpiece
                </h2>
                <div className="flex items-center justify-center py-6  border-white">
                  <button className="makean_enquiry">Learn more</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <video className="" playsInline autoPlay muted loop>
            <source className="" src="./img/slider3.mov" type="video/mp4" />
          </video>
          <div
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
            style={{
              background:
                "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)",
            }}
            // style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
          >
            <div className="flex h-full items-center justify-center">
              <div className="px-md-0 px-14 text-center text-white">
                <h2 className="mb-4 text-md md:text-2xl font-semibold mx-5">
                  BFI's Superior Technological Expertise for Entrepreneurs to
                  Business Professionals
                </h2>
                <div className="flex items-center justify-center py-6  border-white">
                  <button className="makean_enquiry">Learn more</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Slider>
    </div>
  );
};

export default HomeSlider;
