import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import HomeSlider from "../Components/HomeSlider";
import { clientsData, prductData } from "../Data";
import AuthorizedSales from "../Components/AuthorizedSales";

function OurProducts() {
  return (
    <section className=" py-10 px-12">
      <div className="grid grid-flow-row gap-8 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {prductData?.map((post, index) => (
          <div
            key={index}
            className="my-8  bg-[#F0F8FF] p-2 duration-300 hover:-translate-y-1"
          >
            <div className="group relative block overflow-hidden rounded-md transition-all duration-500 h-[350px]">
              <span
                className="lightbox transition-all duration-500 group-hover:scale-105 tobii-zoom"
                title=""
              >
                <img
                  src={`${post.image}?auto=format&fit=crop&w=400&q=50`}
                  alt={`${post.image}+${index}`}
                  className="rounded-md w-full"
                />
              </span>
              <div className="absolute -bottom-52 group-hover:bottom-2 right-2 left-2 transition-all duration-500 bg-[#F0F8FF] p-4 rounded shadow shadow-gray-700">
                <figcaption className="p-4">
                  <p className="text-lg mb-4 font-bold leading-relaxed ">
                    {post.title}
                  </p>
                  <p className="text-sm mb-4 font-bold leading-relaxed text-gray-800">
                    {post.headline}
                  </p>
                  <small className="leading-5 ">{post.description}</small>
                </figcaption>
              </div>
            </div>
            <div className="flex items-center justify-center my-3">
              <Link to={`/product/${post.id}`}>
                <span
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg_primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  Read more
                </span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

function OurClients() {
  return (
    <div className="container my-15 mx-auto md:px-6">
      <section className="mb-32 text-center">
        <h2 className="font-extrabold my-5 uppercase leading-snug	text-center py-5 text-4xl font-teko">
          Our Prestigious Clients
        </h2>
        <div className="grid gap-6 lg:grid-cols-3 xl:gap-x-12">
          <div className="mb-6 lg:mb-0">
            <div className="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="flex">
                <div
                  className="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src="https://mdbcdn.b-cdn.net/img/new/standard/city/024.webp"
                    className="w-full"
                    alt="My Paradise"
                  />
                  <span>
                    <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]"></div>
                  </span>
                </div>
              </div>
              <div className="p-6">
                <h5 className="mb-3 text-lg font-bold">
                  Spartan India PVT LTD
                </h5>
                <p className="mb-4 text-gray-500 font-semibold">
                  <small>
                    Published <u>13.01.2022</u> by <span>Anna Maria Doe</span>
                  </small>
                </p>
              </div>
            </div>
          </div>

          <div className="mb-6 lg:mb-0">
            <div className="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="flex">
                <div
                  className="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src="https://mdbcdn.b-cdn.net/img/new/standard/city/031.webp"
                    className="w-full"
                    alt="Travel to Italy"
                  />
                  <span>
                    <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]"></div>
                  </span>
                </div>
              </div>
              <div className="p-6">
                <h5 className="mb-3 text-lg font-bold">Travel to Italy</h5>
                <p className="mb-4 text-gray-500 font-semibold">
                  <small>
                    Published <u>12.01.2022</u> by <span>Halley Frank</span>
                  </small>
                </p>
              </div>
            </div>
          </div>

          <div className="mb-0">
            <div className="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="flex">
                <div
                  className="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src="https://mdbcdn.b-cdn.net/img/new/standard/city/081.webp"
                    className="w-full"
                    alt="Chasing the sun"
                  />
                  <span>
                    <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]"></div>
                  </span>
                </div>
              </div>
              <div className="p-6">
                <h5 className="mb-3 text-lg font-bold">Chasing the sun</h5>
                <p className="mb-4 text-gray-500 font-semibold">
                  <small>
                    Published <u>10.01.2022</u> by <span>Joe Svan</span>
                  </small>
                </p>
                <a
                  href="#!"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg_primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function OurService() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="container mb-20 mt-18  mx-auto md:px-6">
      <div className="md:mx-10 my-5">
        <h2 className="font-extrabold my-5	leading-snug	text-center py-5 text-4xl font-teko">
          OUR SERVICES
        </h2>
        <p className="text-center text-md">
          BFI focuses on offering edge cutting smarter, innovative, and creative
          AI solutions to help you grow your business effectively and
          efficiently, serving you with better innovative ideas and strategies
        </p>
      </div>
      <div className="flex flex-wrap w-[100%]">
        <div className="p-2 w-[100%] md:w-[30%]">
          <nav
            className="flex flex-col space-y-4"
            aria-label="Tabs"
            role="tablist"
            data-hs-tabs-vertical="true"
          >
            <button
              type="button"
              className={`hs-tab-active:border-blue-500 ${
                activeTab === 1
                  ? "hs-tab-active:text-blue-600 bg-blue-900 text-white ng-red"
                  : "text-gray-500 hover:text-blue-600"
              } py-2 pr-4 px-2 text-lg font-semibold inline-flex bg-[#DCDCDC] text-black items-center font-teko gap-2 border-r-[3px] border-transparent whitespace-nowrap  active`}
              id="vertical-tab-with-border-item-1"
              data-hs-tab="#vertical-tab-with-border-1"
              aria-controls="vertical-tab-with-border-1"
              role="tab"
              onClick={() => handleTabClick(1)}
            >
              01. Custom Software Development
            </button>
            <button
              type="button"
              className={`hs-tab-active:border-blue-500 ${
                activeTab === 2
                  ? "hs-tab-active:text-blue-600 bg-blue-900 text-white"
                  : "text-gray-500 hover:text-blue-600"
              } py-2 pr-4 px-2 text-lg font-semibold inline-flex bg-[#DCDCDC] text-black items-center font-teko gap-2 border-r-[3px] border-transparent whitespace-nowrap 
               active`}
              id="vertical-tab-with-border-item-2"
              data-hs-tab="#vertical-tab-with-border-2"
              aria-controls="vertical-tab-with-border-2"
              role="tab"
              onClick={() => handleTabClick(2)}
            >
              02. Software Consulting
            </button>
            <button
              type="button"
              className={`hs-tab-active:border-blue-500 ${
                activeTab === 3
                  ? "hs-tab-active:text-blue-600 bg-blue-900 text-white"
                  : "text-gray-500 hover:text-blue-600"
              } py-2 pr-4 px-2 text-lg font-semibold inline-flex bg-[#DCDCDC] text-black items-center font-teko gap-2 border-r-[3px] border-transparent whitespace-nowrap active`}
              id="vertical-tab-with-border-item-3"
              data-hs-tab="#vertical-tab-with-border-3"
              aria-controls="vertical-tab-with-border-3"
              role="tab"
              onClick={() => handleTabClick(3)}
            >
              03. E-Commerce Solutions
            </button>
            <button
              type="button"
              className={`hs-tab-active:border-blue-500 ${
                activeTab === 4
                  ? "hs-tab-active:text-blue-600 bg-blue-900 text-white"
                  : "text-gray-500 hover:text-blue-600"
              } py-2 pr-4 px-2 text-lg font-semibold inline-flex bg-[#DCDCDC] text-black items-center font-teko gap-2 border-r-[3px] border-transparent whitespace-nowrap active`}
              id="vertical-tab-with-border-item-4"
              data-hs-tab="#vertical-tab-with-border-4"
              aria-controls="vertical-tab-with-border-4"
              role="tab"
              onClick={() => handleTabClick(4)}
            >
              04. Apps Development
            </button>
            <button
              type="button"
              className={`hs-tab-active:border-blue-500 ${
                activeTab === 5
                  ? "hs-tab-active:text-blue-600 bg-blue-900 text-white"
                  : "text-gray-500 hover:text-blue-600"
              } py-2 pr-4 px-2 text-lg font-semibold inline-flex bg-[#DCDCDC] text-black items-center font-teko gap-2 border-r-[3px] border-transparent whitespace-nowrap active`}
              id="vertical-tab-with-border-item-5"
              data-hs-tab="#vertical-tab-with-border-5"
              aria-controls="vertical-tab-with-border-5"
              role="tab"
              onClick={() => handleTabClick(5)}
            >
              05. Cyber Security
            </button>
          </nav>
        </div>

        <div className="p-2 w-[100%] md:w-[70%]">
          <div
            id="vertical-tab-with-border-2"
            className={activeTab === 1 ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="vertical-tab-with-border-item-2"
          >
            <div className="grid gap-6 lg:grid-cols-2 xl:gap-x-12">
              <div className="w-full">
                <img
                  src="./img/softwaredevelopment.jpg"
                  alt="softwaredevelopment.jpg"
                  className="h-full w-full"
                />
              </div>
              <div>
                <h3 className="text-3xl font-bold mb-5">
                  Custom Software Development
                </h3>
                <p className="text-justify">
                  Our specialised Customized Software Development is the process
                  of designing, building, deploying, and maintaining software
                  for all types of SME’s and MSME’s that is tailored to their
                  needs and aims to improve every industry to a new level.
                </p>
              </div>
            </div>
          </div>
          <div
            id="vertical-tab-with-border-2"
            className={activeTab === 2 ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="vertical-tab-with-border-item-2"
          >
            <div className="grid gap-6 lg:grid-cols-2 xl:gap-x-12">
              <div className="w-full">
                <img
                  src="./img/softwareconsulting.jpg"
                  alt="softwareconsulting.jpg"
                  className="h-full w-full"
                />
              </div>
              <div>
                <h3 className="text-3xl font-bold mb-5">
                  Software Consultancy
                </h3>
                <p className="text-justify">
                  BFI diagnose, analyze, identify, plan, and offer solutions
                  that are compatible with every organization's needs.
                  Expertizing our knowledge and experience at all instances
                  accelerates.
                </p>
              </div>
            </div>
          </div>
          <div
            id="vertical-tab-with-border-3"
            className={activeTab === 3 ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="vertical-tab-with-border-item-3"
          >
            <div className="grid gap-6 lg:grid-cols-2 xl:gap-x-12">
              <div className="w-full">
                <img
                  src="./img/ecommerce.jpg"
                  alt="ecommerce.jpg"
                  className="h-full w-full"
                />
              </div>
              <div>
                <h3 className="text-3xl font-bold mb-5">
                  E-Commerce Solutions
                </h3>
                <p className="text-justify">
                  Our E-Commerce Software facilitates quicker and trusted growth
                  of income and profit for both, beginner's playground, and an
                  expert home field by meeting the demands of any small- to
                  medium-sized firm.
                </p>
              </div>
            </div>
          </div>
          <div
            id="vertical-tab-with-border-4"
            className={activeTab === 4 ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="vertical-tab-with-border-item-4"
          >
            <div className="grid gap-6 lg:grid-cols-2 xl:gap-x-12">
              <div className="w-full">
                <img
                  src="./img/apps.jpg"
                  alt="apps.jpg"
                  className="h-full w-full"
                />
              </div>
              <div>
                <h3 className="text-3xl font-bold mb-5">Apps Development</h3>
                <p className="text-justify">
                  Our Application Development assists you in creating beneficial
                  and efficient experiences on every device and help you get the
                  most out of mobile Technology for your business with our
                  Application Development Services, which draw on our team's
                  strong expertise in developing Customized Applications.
                </p>
              </div>
            </div>
          </div>
          <div
            id="vertical-tab-with-border-5"
            className={activeTab === 5 ? "" : "hidden"}
            role="tabpanel"
            aria-labelledby="vertical-tab-with-border-item-5"
          >
            <div className="grid gap-6 lg:grid-cols-2 xl:gap-x-12">
              <div className="w-full">
                <img
                  src="./img/cyber.jpg"
                  alt="cyber.jpg"
                  className="h-full w-full"
                />
              </div>
              <div>
                <h3 className="text-3xl font-bold mb-5">Cyber Security</h3>
                <p className="text-justify">
                  To guarantee that your systems and data are safe, BFI Cyber
                  Security employs a patented technique to execute a controlled
                  external real-life evaluation and penetration test of your
                  firewalls, network entry points, and public IP addresses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Home = () => {
  return (
    <div>
      <HomeSlider />

      <section className="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] overflow-hidden w-[95%] mx-2 px-10">
        <div className="flex flex-wrap items-center justify-between -mx-4">
          <div className="w-full px-4 lg:w-6/12">
            <div className="flex items-center -mx-3 sm:-mx-4">
              <div className="w-full px-3 sm:px-4 xl:w-1/2">
                <div className="py-3 sm:py-4">
                  <img
                    src="./img/about1.jpg"
                    alt=""
                    className="w-full rounded-2xl"
                  />
                </div>
                <div className="py-3 sm:py-4">
                  <img
                    src="./img/home1.jpg"
                    alt=""
                    className="w-full rounded-2xl"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:px-4 xl:w-1/2">
                <div className="relative z-10 my-4">
                  <img
                    src="./img/home2.jpg"
                    alt=""
                    className="w-full rounded-2xl"
                  />
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-4 py-3 bg-gray-500/50 w-full flex items-center justify-center flex-col text-center h-full ">
                    <div></div>
                    <h1 className="text-white flex items-center justify-center font-bold h-[100px] w-[100px] mx-auto text-4xl border-2 border-black text-center">
                      5
                    </h1>
                    <p className="text-white font-semibold text-1xl my-2">
                      Years Experience
                    </p>
                  </div>
                  <span className="absolute -right-7 -bottom-7 z-[-1]">
                    <svg
                      width={134}
                      height={106}
                      viewBox="0 0 134 106"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="1.66667"
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 1.66667 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 16.3333 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 31 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 45.6667 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3334"
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 60.3334 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 88.6667 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 117.667 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 74.6667 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 103 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy={104}
                        r="1.66667"
                        transform="rotate(-90 132 104)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.66667"
                        cy="89.3333"
                        r="1.66667"
                        transform="rotate(-90 1.66667 89.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy="89.3333"
                        r="1.66667"
                        transform="rotate(-90 16.3333 89.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy="89.3333"
                        r="1.66667"
                        transform="rotate(-90 31 89.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy="89.3333"
                        r="1.66667"
                        transform="rotate(-90 45.6667 89.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3333"
                        cy="89.3338"
                        r="1.66667"
                        transform="rotate(-90 60.3333 89.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy="89.3338"
                        r="1.66667"
                        transform="rotate(-90 88.6667 89.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy="89.3338"
                        r="1.66667"
                        transform="rotate(-90 117.667 89.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy="89.3338"
                        r="1.66667"
                        transform="rotate(-90 74.6667 89.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy="89.3338"
                        r="1.66667"
                        transform="rotate(-90 103 89.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy="89.3338"
                        r="1.66667"
                        transform="rotate(-90 132 89.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.66667"
                        cy="74.6673"
                        r="1.66667"
                        transform="rotate(-90 1.66667 74.6673)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.66667"
                        cy="31.0003"
                        r="1.66667"
                        transform="rotate(-90 1.66667 31.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 16.3333 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy="31.0003"
                        r="1.66667"
                        transform="rotate(-90 16.3333 31.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 31 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy="31.0003"
                        r="1.66667"
                        transform="rotate(-90 31 31.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 45.6667 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy="31.0003"
                        r="1.66667"
                        transform="rotate(-90 45.6667 31.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3333"
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 60.3333 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3333"
                        cy="30.9998"
                        r="1.66667"
                        transform="rotate(-90 60.3333 30.9998)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 88.6667 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy="30.9998"
                        r="1.66667"
                        transform="rotate(-90 88.6667 30.9998)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 117.667 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy="30.9998"
                        r="1.66667"
                        transform="rotate(-90 117.667 30.9998)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 74.6667 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy="30.9998"
                        r="1.66667"
                        transform="rotate(-90 74.6667 30.9998)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 103 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy="30.9998"
                        r="1.66667"
                        transform="rotate(-90 103 30.9998)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy="74.6668"
                        r="1.66667"
                        transform="rotate(-90 132 74.6668)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy="30.9998"
                        r="1.66667"
                        transform="rotate(-90 132 30.9998)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.66667"
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 1.66667 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.66667"
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 1.66667 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 16.3333 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 16.3333 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 31 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 31 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 45.6667 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 45.6667 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3333"
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 60.3333 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3333"
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 60.3333 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 88.6667 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 88.6667 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 117.667 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 117.667 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 74.6667 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 74.6667 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 103 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 103 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy="60.0003"
                        r="1.66667"
                        transform="rotate(-90 132 60.0003)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy="16.3333"
                        r="1.66667"
                        transform="rotate(-90 132 16.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.66667"
                        cy="45.3333"
                        r="1.66667"
                        transform="rotate(-90 1.66667 45.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.66667"
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 1.66667 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy="45.3333"
                        r="1.66667"
                        transform="rotate(-90 16.3333 45.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="16.3333"
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 16.3333 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy="45.3333"
                        r="1.66667"
                        transform="rotate(-90 31 45.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={31}
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 31 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy="45.3333"
                        r="1.66667"
                        transform="rotate(-90 45.6667 45.3333)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="45.6667"
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 45.6667 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3333"
                        cy="45.3338"
                        r="1.66667"
                        transform="rotate(-90 60.3333 45.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="60.3333"
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 60.3333 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy="45.3338"
                        r="1.66667"
                        transform="rotate(-90 88.6667 45.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="88.6667"
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 88.6667 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy="45.3338"
                        r="1.66667"
                        transform="rotate(-90 117.667 45.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="117.667"
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 117.667 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy="45.3338"
                        r="1.66667"
                        transform="rotate(-90 74.6667 45.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="74.6667"
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 74.6667 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy="45.3338"
                        r="1.66667"
                        transform="rotate(-90 103 45.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={103}
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 103 1.66683)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy="45.3338"
                        r="1.66667"
                        transform="rotate(-90 132 45.3338)"
                        fill="#3056D3"
                      />
                      <circle
                        cx={132}
                        cy="1.66683"
                        r="1.66667"
                        transform="rotate(-90 132 1.66683)"
                        fill="#3056D3"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
            <div className="mt-10 lg:mt-0">
              <span className="block mb-2 text-lg font-semibold text-blue-400">
                ABOUT US
              </span>
              <p className="mb-8 text-base text-slate-600	 text-justify">
                Proudly based in Navi Mumbai, India, BFI Infotech is one of the
                most advanced & leading Information Technology Products and
                Service providers in India & across the globe. Our goal is to
                provide advanced and effective Technology products and services
                to promote the overall growth of our clients in this challenging
                world of large-scale IT industry.
                <br />
                <br />
                BFI strongly hold that reputed 05 years of rich experience
                serving as Technological expert, consistently automating your
                business, and bringing your ideas into reality through our IT
                expertise.
              </p>
              <div className=" w-full my-10">
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2">
                  <div className="flex flex-col gap-2">
                    <h3 className="flex gap-5 items-center">
                      <FaCheck className="text-orange-400 text-md font-bold" />
                      <span className="font-semibold text-md">
                        Quality Services
                      </span>
                    </h3>
                    <h3 className="flex gap-5 items-center">
                      <FaCheck className="text-orange-400 text-md font-bold" />
                      <span className="font-semibold text-md">
                        Competitive Cost
                      </span>
                    </h3>
                    <h3 className="flex gap-5 items-center">
                      <FaCheck className="text-orange-400 text-md font-bold" />
                      <span className="font-semibold text-md">
                        Economic Efficiencies
                      </span>
                    </h3>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="flex gap-5 items-center">
                      <FaCheck className="text-orange-400 text-md font-bold" />
                      <span className="font-semibold text-md">
                        Innovative Digitalization
                      </span>
                    </h3>
                    <h3 className="flex gap-5 items-center">
                      <FaCheck className="text-orange-400 text-md font-bold" />
                      <span className="font-semibold text-md">
                        Updated Technology
                      </span>
                    </h3>
                    <h3 className="flex gap-5 items-center">
                      <FaCheck className="text-orange-400 text-md font-bold" />
                      <span className="font-semibold text-md">
                        24/7 Technical Support
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-3 lg:gap-x-12 my-5">
                <div className="mb-12 md:mb-0">
                  <h2 className="mb-4 text-3xl font-bold text-primary">
                    200<sup>+</sup>
                  </h2>
                  <h5 className="mb-0 text-md font-medium text-black">
                    Happy Clients
                  </h5>
                </div>
                <div className="mb-12 md:mb-0">
                  <h2 className="mb-4 text-3xl font-bold text-primary">5</h2>
                  <h5 className="mb-0 text-md font-medium text-black">
                    Own Products
                  </h5>
                </div>
                <div className="mb-12 md:mb-0">
                  <h2 className="mb-4 text-3xl font-bold text-primary">80%</h2>
                  <h5 className="mb-0 text-md font-medium text-black">
                    Growth
                  </h5>
                </div>
              </div>
              <Link to="/about">
                <button className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 ">
                  <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0 text-center inline-flex items-center">
                    Read More
                    <svg
                      className="w-3.5 h-3.5 ml-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className=" px-6 py-4 mb-10">
        <iframe
          className="w-full md:h-[95vh]  aspect-video border-none"
          src="https://www.youtube.com/embed/KIajzSVsn_U?rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </section>

      <section
        className="bg-cover max-w-full h-auto my-1"
        style={{ backgroundImage: "url('./img/homeourprod.jpg')" }}
      >
        <div className="text-center w-[60%] m-auto py-3 text-white font-teko">
          <h5 className="text-2xl font-[500]">Our Products</h5>
          <h6 className="text-4xl font-[800]">
            Exclusively Advanced Software Technological Products
          </h6>
        </div>
        {OurProducts()}
      </section>
      <div
        className="bg-cover max-w-full h-auto my-1 py-3"
        style={{ backgroundImage: "url('./img/homedifferent.png')" }}
      >
        <div className="text-center mt-5">
          <h2 className="font-extrabold	leading-snug	 py-5 text-4xl font-teko">
            How We Are Different
          </h2>
        </div>
        <div className="md:p-5 mx-auto w-[90%] mb-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
            <div className="md:py-4 md:px-7  rounded-md">
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h6 className="font-semibold text-2xl font-teko">
                      One Click Solutions
                    </h6>
                    <p className="font-sans text-sm text-justify text-black">
                      BFI specialises in automating laborious procedures by
                      providing One Click Solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h5 className="font-semibold text-2xl font-teko">
                      Consistent Improvisation
                    </h5>
                    <p className="font-sans text-sm text-justify text-black">
                      We strive perpetual improvements where customer's growth
                      is our success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h5 className="font-semibold text-2xl font-teko">
                      Advanced Technology
                    </h5>
                    <p className="font-sans text-sm text-justify text-black">
                      Our inhouse team of modern Tech wizards are consistently
                      serving every industry to upgrade Technology efficiently.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h5 className="font-semibold text-2xl font-teko">
                      Adapt Upgradation
                    </h5>
                    <p className="font-sans text-sm text-justify text-black">
                      Research, design, develop, adapt and upgrade as per need
                      of the business by keeping future requirements in mind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:py-4 md:px-7  rounded-md">
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h6 className="font-semibold text-2xl font-teko">
                      Creative IT Solutions
                    </h6>
                    <p className="font-sans text-sm text-justify text-black">
                      We make creative and edge-cutting IT Solutions to turn
                      your ambitions into reality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h5 className="font-semibold text-2xl font-teko">
                      24*7 Technology Support
                    </h5>
                    <p className="font-sans text-sm text-justify text-black">
                      We provide 24*7 worldwide Technological Support and
                      software maintenance services that are flexible and
                      user-friendly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h5 className="font-semibold text-2xl font-teko">
                      Paperless World
                    </h5>
                    <p className="font-sans text-sm text-justify text-black">
                      We protect the environment through advancing Technology,
                      which makes operations more Paperless.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex  w-full items-center gap-4">
                  <img
                    src="./img/icongif.gif"
                    alt="icongif.gif"
                    className="h-16"
                  />
                  <div>
                    <h5 className="font-semibold text-2xl font-teko">
                      Customised Methodology
                    </h5>
                    <p className="font-sans text-sm text-justify text-black">
                      We are pioneers in understanding the demands of every
                      industry and personalizing Technology to meet those needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {OurService()}

      <section className="bg-gray-900">
        <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
          <h2 className="font-extrabold mt-5 mb-10 uppercase leading-snug text-center text-white py-3 text-3xl md:text-4xl font-teko">
            Our Prestigious Clients
          </h2>
          <div className="grid grid-cols-2 gap-8 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 text-gray-400">
            {clientsData.map((client) => (
              <div
                key={client.id}
                className="flex justify-center items-center flex-col brightness-50 hover:brightness-100"
              >
                <img
                  src={client.imageSrc}
                  alt={`client${client.id}`}
                  className="h-20 w-[100%] object-contain"
                />
                <span className="my-2 text-sm text-white font-toko capitalize text-center ">
                  {client.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>
      {AuthorizedSales()}
    </div>
  );
};

export default Home;
